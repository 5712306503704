import { defineComponent } from 'vue-demi';

import { usePageContent } from '..';

/**
 * Support mixin mode
 */
export const PageContentMixin = <T = any>() => defineComponent({
  setup: () => usePageContent<T>(),
});
