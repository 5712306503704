import { DocumentNode } from 'graphql';
import { gql } from 'graphql-tag';
import { print } from 'graphql/language/printer';

import { RouterError, RouterErrorType } from './error';
import { getApolloClient } from './state';
import { debugOp, getGraphQLFragmentUsageString } from './utils';

export interface PageMetaProperties {
  title: string
  description: string
  publishedDate: Date
  modifiedDate: Date
  image: {
    variant: {
      id: string
      width: number
      height: number
      url: string
    }
  }
}

const pageContentQuery = gql`
  query pageContent ($url: String!) {
    workspace {
      url
    }
    page (url: $url) {
      id
      content @inject { id }
      metaJsonld
      metaProperties {
        title
        description
        datePublished
        dateModified
        canonicalUrl
        image {
          id
          variant (preset: og) {
            id
            width
            height
            url
          }
        }
      }
    }
  }
`;

const pageContentQueryString = print(pageContentQuery);
const fragmentPlaceholderPattern = /content @inject\s*{\s*id\s*}/;

export const fetchPageContent = async (url: string, pageContentFragment: DocumentNode | null) => {
  let fragmentUsageString = '';
  if (pageContentFragment)
    fragmentUsageString = `content { id ${getGraphQLFragmentUsageString(pageContentFragment)} }`;

  const query = gql(
    [pageContentQueryString.replace(fragmentPlaceholderPattern, fragmentUsageString), ''],
    pageContentFragment,
  );

  const client = getApolloClient();
  const { data } = await debugOp('page-content')(
    client.query({
      query,
      fetchPolicy: 'cache-first',
      variables: {
        url,
      },
    }),
  ).catch(e => Promise.reject(new RouterError(RouterErrorType.FetchError, e)));

  return {
    pageContentId: data.page.content.id,
    pageContent: data.page.content,
    pageMetaProperties: data.page.metaProperties as PageMetaProperties,
    pageMetaJsonld: data.page.metaJsonld,
    workspaceContent: data.workspace,
  };
};
