export enum RouterErrorType {
  Unknown = 'Unknown',
  PageNotFound = 'Page not found',
  ContentTypeNotImplemented = 'Content type is not implemented',
  FetchError = 'Fetch page content query failed',
  Redirected = 'Redirected',
}

export class RouterError extends Error {
  private errorType: RouterErrorType;
  public pageContentType?: string;
  public nativeError?: Error;

  constructor (errorType: RouterErrorType, nativeError?: Error) {
    super(errorType);

    this.name = 'RouterError';
    this.errorType = errorType;
    this.nativeError = nativeError;
  }

  get type () {
    return this.errorType;
  }

  public withContentType (pageContentType: string) {
    this.pageContentType = pageContentType;
    return this;
  }
}
