/**
 * Create logger function
 * @param {string} namespace
 * @returns {(...args: any[]) => void}
 */
export const createDebugger = (namespace) => {
  try {
    const debug = require('debug');
    return debug(`nuxt-apollo:${namespace}`);
  } catch (e) {
    return () => undefined;
  }
};
