import type { ApolloClient } from '@apollo/client/core';
import { Context } from '@nuxt/types';

import * as Blocks from '@/cms/blocks';

let apolloClient: ApolloClient<any>;
export const setApolloClient = (client: typeof apolloClient) => { apolloClient = client };
export const getApolloClient = () => apolloClient;

let nuxtContext: Context;
export const setNuxtContext = (comp: typeof nuxtContext) => { nuxtContext = comp };
export const getNuxtContext = () => nuxtContext;

let blocksCollection: Record<string, () => { component: Promise<any> }>;
blocksCollection = Blocks as any; // TODO: Replace with config
export const setBlocksCollection = (b: typeof blocksCollection) => { blocksCollection = b };
export const getBlocksCollection = () => blocksCollection;
