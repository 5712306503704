







import { defineAsyncComponent, defineComponent } from '@nuxtjs/composition-api';

import { usePreviewMode } from '../utils/preview-mode';

export default defineComponent({
  name: 'PreviewButton',
  components: {
    PreviewButtonInner: defineAsyncComponent(() => import('./preview-button-inner.vue')),
  },
  inheritAttrs: false,
  setup: () => usePreviewMode(),
});
