import { DocumentNode } from 'graphql';

import { runHook } from '../hooks';

import { generateBlocksFragment } from './blocks-fragment';
import { RouterError, RouterErrorType } from './error';
import { fetchPageContent, PageMetaProperties } from './page-content';
import { fetchPageContentType } from './page-content-type';
import { getPageFragment } from './page-fragment';
import { createDebugger } from './utils';

interface Orchestrate<T = any> {
  pageLayout: string | null
  pageContentType: string | null
  pageContentId: string | null
  pageContent: T | null
  pageMetaProperties: PageMetaProperties | null
  pageMetaJsonld: any
  workspaceContent: {
    url: string
  }
}

export const fragmentsCache = new Map<string, DocumentNode>();
export const layoutCache = new Map<string, string>();

/**
 * Orchestrate page content fetching steps
 * @param url Current URL
 */
export const orchestrate = async <T = any>(url: string): Promise<Orchestrate<T>> => {
  const debug = createDebugger('orchestrate');
  debug('Start');

  // Fetch content type and possible blocks on page
  const { done: pageContentTypeDone } = runHook('fetch:page-content-type');
  const { pageContentType, blockTypes, pageFound } = await fetchPageContentType<any>(url);
  pageContentTypeDone({ pageContentType, pageFound });

  if (!pageFound)
    throw new RouterError(RouterErrorType.PageNotFound);

  // Try use page fragment from cache if exists
  let pageFragment = fragmentsCache.get(pageContentType)!;
  let pageLayout = layoutCache.get(pageContentType)!;

  // If no cache hit
  if (!pageFragment) {
    // Get graphql fragment from page component
    // If page component could not be resolved, exit early
    const { pageFragment: fragment, pageLayout: layout } = await getPageFragment(pageContentType);
    if (!fragment)
      return {
        pageLayout: null,
        pageContentType,
        pageContentId: null,
        pageContent: {} as T,
        pageMetaProperties: null,
        pageMetaJsonld: null,
        workspaceContent: null,
      };

    // Generate single blocks fragment from all block
    // components and apply it to the page fragment
    const { applyBlocksFragment } = await generateBlocksFragment(blockTypes);
    pageFragment = applyBlocksFragment(fragment);
    pageLayout = layout;

    // Save fragment to cache
    fragmentsCache.set(pageContentType, pageFragment);
    layoutCache.set(pageContentType, pageLayout);
  }

  // Finally fetch page content using the generated fragment
  const { done: pageContentDone } = runHook('fetch:page-content');
  const pageContentResult = await fetchPageContent(url, pageFragment);
  pageContentDone(pageContentResult);

  debug('Completed');

  return {
    pageLayout,
    pageContentType,
    ...pageContentResult,
  };
};
