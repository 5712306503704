import type { DocumentNode } from '@apollo/client/core';
import { gql } from 'graphql-tag';
import { print } from 'graphql/language/printer';

import { getBlocksCollection } from './state';
import { getGraphQLFragmentUsageString, hashCode } from './utils';

interface BlockFragment {
  name: string
  fragment: DocumentNode
  usageString: string
}

interface GenerateBlocksFragment {
  applyBlocksFragment: (fragment: DocumentNode) => DocumentNode
}

const fragmentPlaceholderPattern = /(\w+: )?__typename @inject/g;

export const generateBlocksFragment = async (blockTypes: string[]): Promise<GenerateBlocksFragment> => {
  const blocksCollection = getBlocksCollection();

  const blockFragmentPromises = blockTypes
  // Create set with name and block component
    .map(blockName => ({
      name: blockName,
      component: blocksCollection[blockName],
    }))
    .map(block => new Promise<BlockFragment>((resolve, reject) => {
      // Reject if block component was not found
      if (!block.component)
        return reject(new Error(`Block implementation was not found for ${block.name}`));

      // Load async component
      const asyncComponent = block.component;
      return asyncComponent().component
        .then((component) => {
          // Get GraphQL fragment from the component
          // Support both class component syntax and defineComponent syntax
          const graphqlData: DocumentNode | null = component?.graphqlData || component?.options?.graphqlData;

          if (graphqlData) {
            resolve({
              name: block.name,
              fragment: graphqlData,
              usageString: getGraphQLFragmentUsageString(graphqlData),
            });
          } else {
            if (process.env.NODE_ENV === 'development')
              console.warn(`Skipping data fetch for ${block.name} since it does not specify a GraphQL fragment`);

            resolve({
              name: block.name,
              fragment: graphqlData,
              usageString: '',
            });
          }
        })
        .catch(reject);
    }));

  const blockFragments = (await Promise.allSettled(blockFragmentPromises))
    .reduce((fragments, promise) => {
      // Add valid fragments to array
      if (promise.status === 'fulfilled') {
        fragments.push(promise.value);
      } else {
        console.groupCollapsed('Unable to load block');
        console.error(promise.reason);
        console.groupEnd();
      }

      return fragments;
    }, [] as BlockFragment[]);

  // Exit early if no fragments were found/successfully resolved
  if (blockFragments.length === 0)
    return {
      applyBlocksFragment: fragment =>
        gql(print(fragment).replace(fragmentPlaceholderPattern, 'id\ntype')),
    };

  // Workaround to make graphql-codegen skip this fragment when generating types
  const notGql = gql;

  // Generate unique fragment name
  const fragmentName = `B${hashCode(blockTypes.join(''))}`;

  // Intentional use of template literal function
  const blocksFragment = notGql(`
    fragment ${fragmentName} on Block {
      id
      type
      content {
        ${blockFragments.map(block => block.usageString).join('\n')}
      }
    }

    ${blockFragments.map(block => print(block.fragment)).join('\n')}
  `);

  return {
    applyBlocksFragment: pageFragment =>
      gql(
        [print(pageFragment).replace(fragmentPlaceholderPattern, `...${fragmentName}`), ''],
        blocksFragment,
      ),
  };
};
