import { gql } from 'graphql-tag';

import { RouterError, RouterErrorType } from './error';
import { getApolloClient, getNuxtContext } from './state';
import { debugOp } from './utils';

const pageContentTypeQuery = gql`
  query pageContentType ($url: String!) {
    page (url: $url) {
      id
      contentType
      redirectUrl
      content {
        id
        blockTypes
      }
    }
  }
`;

export const fetchPageContentType = async <T>(url: string) => {
  const client = getApolloClient();
  const { redirect } = getNuxtContext();

  const { data } = await debugOp('content-type')(
    client.query({
      query: pageContentTypeQuery,
      fetchPolicy: 'cache-first',
      variables: {
        url,
      },
    }),
  );

  if (data.page?.redirectUrl) {
    redirect(data.page.redirectUrl);
    return Promise.reject(new RouterError(RouterErrorType.Redirected));
  }

  return {
    pageFound: !!data.page,
    pageContentType: data.page?.contentType as string,
    blockTypes: data.page?.content.blockTypes as T,
  };
};
