










import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component({
  layout: 'default',
})
export default class ErrorPage extends Vue {
  @Prop(Object)
  error!: any

  backToIndexText = 'Tillbaka till startsidan';

  get statusCode () {
    const code = (this.error && this.error.statusCode) || 500;
    return code.toString();
  }

  get errorMessage () {
    if (this.error && this.error.statusCode === 404)
      return 'Sidan kunde inte hittas';
    else
      return this.error.message || 'Något gick fel';
  }

  created () {
    // @ts-ignore
    if (this.$appsignal)
      // @ts-ignore
      this.$appsignal.sendError(new Error(`${this.error.statusCode}: ${this.error.message}`));
  }
}
