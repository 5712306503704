import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';

import { introspection } from '@/graphql/generated';
import { defineApolloClient } from '@/modules/apollo';
import { getPreviewToken } from '@/modules/cms';

// Support merged query results and use of fetchMore() utility
const offsetPaginationMerger = <T = any>(existing: T[], incoming: T[], { args: { offset = 0, limit = Infinity } }) => {
  const merged = existing ? existing.slice(0) : [];

  // Insert the incoming elements in the right places, according to args.
  const end = offset + Math.min(limit, incoming.length);
  for (let i = offset; i < end; ++i)
    merged[i] = incoming[i - offset];

  return merged;
};

export default defineApolloClient((context) => {
  const { $config } = context;

  // Define your apollo client
  const httpLink = createHttpLink({
    uri: $config.graphqlEndpoint,
    headers: {
      'X-Workspace-Token': $config.workspaceToken,
      'X-Preview-Token': getPreviewToken(context),
    },
  });

  const cache = new InMemoryCache({
    possibleTypes: introspection.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          scenkonstOtPerformances: {
            keyArgs: ['seasons', 'archived', 'limit', 'performanceTags'],
            merge: offsetPaginationMerger,
          },
          news: {
            keyArgs: ['limit'],
            merge: offsetPaginationMerger,
          },
        },
      },
    },
  });
  const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
    ssrMode: process.server,
  });

  return apolloClient;
});
