














import { defineAsyncComponent, defineComponent } from 'vue-demi';
import type { PropType } from 'vue-demi';

import { usePageContent } from '../composables/page-content';
import { getBlocksCollection } from '../fetch/state';
import { PageContentMixin } from '../mixins/page-content';
import { isDevMode } from '../utils/debug';

interface Block {
  id: string
  type: string
  content?: any
}

// Component resolver
interface ResolvedComponent {
  component: any;
  props?: any;
}

const MissingBlock = defineAsyncComponent(() => import('./missing-block.vue'));

export default defineComponent({
  name: 'BlocksRenderer',
  mixins: [PageContentMixin()],
  props: {
    items: {
      type: Array as PropType<Block[]>,
      required: false,
      default: undefined,
    },
  },
  setup (props) {
    const { pageContent } = usePageContent();
    const blocksCollection = getBlocksCollection();

    // Use default blocks key if none is provided
    let blocks: Block[] = props.items!;
    if (blocks === undefined)
      blocks = pageContent.value.blocks || [];

    const resolveBlockComponent = (block: Block): ResolvedComponent => {
      // Find block component implementation in project
      const component = blocksCollection[block.type];

      // If block content is missing (e.g. due to invalid cached fragment)
      if (!block.content)
        return { component: 'div' };

      if (!component)
        // Show placeholder block in development
        if (isDevMode)
          return {
            component: MissingBlock,
            props: { blockType: block.type },
          };
        // Render nothing in production
        else
          return { component: 'div' };

      // Render block component
      return {
        component,
        props: { data: block.content },
      };
    };

    return {
      blocks,
      resolveBlockComponent,
    };
  },
});
