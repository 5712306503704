/**
 * Quite ugly workaround for make tree shaking work with @iconscout/vue-unicons.
 * Importing from @iconscout/vue-unicons directly will make vue-loader transpile ALL available icons.
 * https://github.com/vuejs/vue-loader/issues/1032
 */

export { default as IconAngleUp } from '@iconscout/vue-unicons/icons/uil-angle-up.vue';
export { default as IconAngleRight } from '@iconscout/vue-unicons/icons/uil-angle-right.vue';
export { default as IconAngleDown } from '@iconscout/vue-unicons/icons/uil-angle-down.vue';
export { default as IconAngleLeft } from '@iconscout/vue-unicons/icons/uil-angle-left.vue';
export { default as IconArrowUp } from '@iconscout/vue-unicons/icons/uil-arrow-up.vue';
export { default as IconArrowRight } from '@iconscout/vue-unicons/icons/uil-arrow-right.vue';
export { default as IconArrowDown } from '@iconscout/vue-unicons/icons/uil-arrow-down.vue';
export { default as IconArrowLeft } from '@iconscout/vue-unicons/icons/uil-arrow-left.vue';
export { default as IconClose } from '@iconscout/vue-unicons/icons/uil-multiply.vue';
export { default as IconEllipsisH } from '@iconscout/vue-unicons/icons/uil-ellipsis-h.vue';
export { default as IconEllipsisV } from '@iconscout/vue-unicons/icons/uil-ellipsis-v.vue';
export { default as IconExpand } from '@iconscout/vue-unicons/icons/uil-expand-alt.vue';
export { default as IconFile } from '@iconscout/vue-unicons/icons/uil-file.vue';
export { default as IconHamburger } from '@iconscout/vue-unicons/icons/uil-bars.vue';
export { default as IconImport } from '@iconscout/vue-unicons/icons/uil-import.vue';
export { default as IconPlus } from '@iconscout/vue-unicons/icons/uil-plus.vue';
export { default as IconSearch } from '@iconscout/vue-unicons/icons/uil-search.vue';
export { default as IconSocialFacebook } from '@iconscout/vue-unicons/icons/uil-facebook-f.vue';
export { default as IconSocialInstagram } from '@iconscout/vue-unicons/icons/uil-instagram.vue';
export { default as IconSocialYoutube } from '@iconscout/vue-unicons/icons/uil-youtube.vue';

// Custom icons (not from vue-unicons)
export { default as IconCheckCircle } from '@/components/icons/check-circle.vue';
export { default as IconPause } from '@/components/icons/pause.vue';
export { default as IconPlay } from '@/components/icons/play.vue';
export { default as IconSocialSpotify } from '@/components/icons/spotify.vue';
