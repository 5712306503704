// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Vue from 'vue';

declare module 'vue/types/vue' {
  interface Vue {
    $browsealoud: () => void;
  }
}

export default (_, inject) => {
  if (process.server)
    return inject('browsealoud', () => {});

  let script: HTMLScriptElement | null = null;

  /**
   * Add browsealoud script
   */
  const enableBrowsealoud = () => {
    localStorage.setItem('browsealoud', '1');

    // Add ba.js to <head>
    script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://www.browsealoud.com/plus/scripts/3.1.0/ba.js';
    script.crossOrigin = 'anonymous';
    script.async = true;
    script.defer = true;
    // eslint-disable-next-line max-len
    script.integrity = 'sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ==';

    document.head.appendChild(script);
  };

  /**
   * Remove browsealoud script
   */
  const disableBrowsealoud = () => {
    localStorage.removeItem('browsealoud');
    document.head.removeChild(script);

    script = null;
  };

  /**
   * Toggle browsealoud script
   */
  const toggle = () => {
    if (script)
      disableBrowsealoud();
    else
      enableBrowsealoud();
  };

  // Auto-load if previously enabled
  if (localStorage.getItem('browsealoud') === '1')
    enableBrowsealoud();

  // Inject helpers to components
  inject('browsealoud', toggle);
};
