import { defineComponent, PropType } from '@nuxtjs/composition-api';

export const PageBlockMixin = <T>() => {
  return defineComponent({
    props: {
      data: {
        type: Object as PropType<T>,
        required: true,
      },
    },
  });
};
