import type { DirectiveOptions } from 'vue';
import VueRouter from 'vue-router';

let router: VueRouter;

/**
 * Autolink directive to automatically use <nuxt-link> or <a> based on href
 * Usage:
 *  - Use on <a> elements directly
 *  - Use on container elements which contains <a> elements (e.g. <div v-html="wysiwygContent">)
 */
export const AutolinkDirective: DirectiveOptions = {
  inserted (el, _, vnode) {
    if (!router)
      router = vnode.context.$router;

    if (el.tagName === 'A')
      return el.addEventListener('click', onClick);

    el.querySelectorAll<HTMLAnchorElement>('a[href]')
      .forEach(a => a.addEventListener('click', onClick));
  },
  unbind (el) {
    if (el.nodeType !== Node.ELEMENT_NODE)
      return;

    if (el.tagName === 'A')
      return el.removeEventListener('click', onClick);

    el.querySelectorAll<HTMLAnchorElement>('a[href]')
      .forEach(a => a.removeEventListener('click', onClick));
  },
};

/**
 * Manage click event
 * @param this This context
 * @param event Click event
 */
function onClick (this: HTMLAnchorElement, event: Event) {
  const a = this;
  const isExternal = new URL(a.href).origin !== location.origin;

  if (isExternal)
    window.open(a.href, '_blank', 'noopener noreferrer');
  else if (a.href.startsWith('/'))
    router.push(a.href);
  else
    return;

  event.preventDefault();
};
