import { DocumentNode, FragmentDefinitionNode } from 'graphql/language';

/**
 * Generate selection string for a given fragment
 */
export const getGraphQLFragmentUsageString = (documentNode: DocumentNode) => {
  const fragmentNode = documentNode.definitions[0] as FragmentDefinitionNode;

  const fragmentName = fragmentNode.name.value;
  const blockName = fragmentNode.typeCondition.name.value;

  return String.raw`... on ${blockName} { ...${fragmentName} }`;
};

export const hashCode = (s: string) => {
  return Math.abs(
    s.split('').reduce((a, b) => {
      a = ((a << 5) - a) + b.charCodeAt(0);
      return a & a;
    }, 0),
  );
};

/**
 * Debugger
 */
let dbg: any;
try { dbg = require('debug') } catch (e) {}
export const createDebugger = (namespace: string) => {
  if (dbg) return dbg(`cms-router:${namespace}`);
  else return () => {};
};

/**
 * Debug async operation
 * @param op Operation name
 * @param asyncOp Promise
 */
export const debugOp = (op: string) => async <T>(asyncOp: Promise<T>) => {
  const debug = createDebugger(op);
  debug('Operation started');

  try {
    const d = await asyncOp;
    debug('✔ Operation succeeded');
    return await Promise.resolve(d);
  } catch (e) {
    debug('✗ Operation failed');
    return await Promise.reject(e);
  }
};
