import { disableFragmentWarnings } from '@apollo/client/core';

import { orchestrate } from './orchestrate';

disableFragmentWarnings();

export { setApolloClient, setBlocksCollection, setNuxtContext } from './state';
export { getPageComponent } from './page-fragment';
export { resolvePageComponent } from './component';
export { orchestrate as fetchPageContent };
