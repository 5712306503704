import { computed, inject } from 'vue-demi';

import { PageMetaProperties } from '../fetch/page-content';
import { PROVIDER_PAGE_CONTENT, PROVIDER_PAGE_CONTENT_ID, PROVIDER_PAGE_CONTENT_TYPE, PROVIDER_PAGE_META_PROPERTIES } from '../utils/providers';

/**
 * Use pre-fetched page content as composable
 */
export const usePageContent = <T = any>() => {
  const pageContentType = inject<string>(PROVIDER_PAGE_CONTENT_TYPE)!;
  const pageContentId = inject<string>(PROVIDER_PAGE_CONTENT_ID)!;
  const pageContent = inject<T>(PROVIDER_PAGE_CONTENT)!;
  const pageMetaProperties = inject<PageMetaProperties>(PROVIDER_PAGE_META_PROPERTIES)!;

  return {
    pageContentType: computed(() => pageContentType),
    pageContentId: computed(() => pageContentId),
    pageContent: computed(() => pageContent),
    pageMetaProperties: computed(() => pageMetaProperties),
  };
};
