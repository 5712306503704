import Vue from 'vue';
import { defineComponent } from '@nuxtjs/composition-api';
import Plyr from 'plyr';

const Component = defineComponent({
  name: 'VuePlyr',
  props: {
    /** Options object for plyr config. **/
    options: {
      type: Object,
      required: false,
      default () {
        return {};
      },
    },
  },
  data () {
    return {
      player: {},
    };
  },
  computed: {
    opts () {
      const options = this.options;
      if (
        !Object.prototype.hasOwnProperty.call(
          this.options,
          'hideYouTubeDOMError',
        )
      )
        options.hideYouTubeDOMError = true;

      return options;
    },
  },
  mounted () {
    this.player = new Plyr(this.$el as HTMLElement, this.opts);
  },
  beforeUnmount () {
    try {
      // @ts-ignore
      this.player.destroy();
    } catch (e: any) {
      if (
        !(
          this.opts.hideYouTubeDOMError &&
          e.message === 'The YouTube player is not attached to the DOM.'
        )
      )
      // eslint-disable-next-line no-console
        console.error(e);
    }
  },
  render () {
    const slots = this.$slots.default;
    // @ts-ignore
    return typeof slots === 'function' ? slots()[0] : slots;
  },
});

Vue.component('VuePlyr', Component);
