import { Context } from '@nuxt/types';
import { ref, useContext } from '@nuxtjs/composition-api';

type PreviewTokenContext = Pick<Context, 'route' | '$cookies' | 'redirect' | 'enablePreview'>;

/**
 * Get preview token from url or local storage
 * @param route Route object
 */
export const getPreviewToken = ({ route, $cookies, redirect, enablePreview }: PreviewTokenContext) => {
  const explicitToken = route.query.preview as string;
  const preview = explicitToken || $cookies.get<string>('preview_token');

  if (!preview)
    return null;

  $cookies.set('preview_token', preview, { path: '/', maxAge: 60 * 60 * 24 });
  if (explicitToken)
    redirect(route.path);

  if (enablePreview)
    enablePreview();

  return preview;
};

/**
 * Check preview mode as composable
 */
export const usePreviewMode = () => {
  const { route, $cookies, redirect } = useContext()!;
  const previewToken = getPreviewToken({
    route: route.value,
    $cookies,
    redirect,
  });

  const isPreviewMode = ref(!!previewToken);
  const disablePreviewMode = () => {
    isPreviewMode.value = false;
    $cookies.remove('preview_token');
    location.href = route.value.path;
  };

  return {
    isPreviewMode,
    disablePreviewMode,
  };
};
