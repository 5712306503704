import { DocumentNode } from 'graphql';

import { RouterError, RouterErrorType } from './error';

type ComponentData = { graphqlData?: DocumentNode, layout?: string }
type Component = Vue.Component & ComponentData & { options?: ComponentData };

export const getPageComponent = (contentType: string) => import(`@/cms/pages/${contentType}.vue`) as Promise<{ default: Component }>;
export const getPageFragment = (contentType: string) =>
  getPageComponent(contentType)
    .then(component => component.default)
    .then((pageComponent) => {
      const pageFragment: DocumentNode | null = pageComponent.graphqlData || pageComponent.options?.graphqlData;
      const pageLayout = pageComponent.layout || pageComponent.options?.layout || null;

      return { pageFragment, pageLayout };
    })
    .catch(e => Promise.reject(
      new RouterError(RouterErrorType.ContentTypeNotImplemented, e)
        .withContentType(contentType),
    ));
