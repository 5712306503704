import { Vue, Component } from 'nuxt-property-decorator';

@Component
export default class LockBodyScrollMixin extends Vue {
  lockScroll (lock: boolean) {
    if (lock) {
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
    } else {
      this.resetScroll();
    }
  }

  resetScroll () {
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }

  beforeDestroy () {
    this.resetScroll();
  }
}
