







import { defineComponent, reactive, useMeta, nextTick, onUnmounted } from '@nuxtjs/composition-api';

class RecaptchaValidationError extends Error {}

declare global {
  interface Window {
    grecaptcha: any;
  }
}

export default defineComponent({
  name: 'RecaptchaForm',
  props: {
    sitekey: {
      type: String,
      required: true,
    },
    native: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit, listeners }) {
    // Add reCAPTCHA script
    const { script } = useMeta();
    script.value.push({
      src: `https://www.google.com/recaptcha/api.js?render=${props.sitekey}`,
      async: true,
      defer: true,
    });

    // @ts-ignore
    const recaptchaState = reactive({
      token: '',
      error: null,
    });

    // Check if script is loaded
    const loadRecaptcha = () => window.grecaptcha
      ? Promise.resolve(window.grecaptcha)
      : new Promise(resolve => setTimeout(() => loadRecaptcha().then(resolve), 100));

    const onSubmit = async (event: Event) => {
      // Validate form
      const form = event.target as HTMLFormElement;
      if (!form.checkValidity())
        return;

      // Use native behavior if no listener is attached
      if (recaptchaState.token && props.native)
        return;

      // Prevent native form action
      event.preventDefault();

      // Await reCAPTCHA script ready
      const grecaptcha = await loadRecaptcha();
      await new Promise(resolve => grecaptcha.ready(resolve));

      // Validate browser
      const token = await grecaptcha.execute(props.sitekey, { action: 'submit' });

      if (!token) {
        console.error('Recaptcha token is empty');
        recaptchaState.error = new RecaptchaValidationError('Kunde inte verifiera din webbläsare. Försök igen lite senare.');
        return;
      }

      recaptchaState.token = token;
      recaptchaState.error = null;

      // Await DOM changes
      await nextTick();

      const formData: any = new FormData(form);
      const data = Object.fromEntries(formData);

      if (!props.native)
        // Submit form in background
        fetch(form.action, {
          method: form.method,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then(response => response.json())
          .then((data) => {
            if (data.success === false)
              return Promise.reject(new Error(data.message || 'Form submission failed'));

            emit('success', data);
          })
          .catch((error) => {
            recaptchaState.token = null;
            emit('error', error);
          });

      else if (!listeners.submit)
        // Retrigger submit event
        form.submit();
      else
        // Emit submit event
        emit('submit', event, data);
    };

    onUnmounted(() => {
      document.querySelector('.grecaptcha-badge')?.remove();
    });

    return {
      recaptchaState,
      onSubmit,
    };
  },
  head: {},
});
