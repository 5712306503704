import type { Plugin } from '@nuxt/types';

declare global {
  interface Window {
    dataLayer: any[];
    gtag: Function;
  }
}

window.dataLayer = window.dataLayer || [];
function gtag (..._: any) { window.dataLayer.push(arguments) }
window.gtag = gtag;

const gtmPlugin: Plugin = function ({ app, $config }) {
  app.router.afterEach((to) => {
    setTimeout(() => {
      // Google
      gtag('event', 'page_view', {
        page_title: document.title,
        page_path: to.fullPath,
        app_version: $config.appVersion,
      });

    // Make sure page transition is completed
    }, 1000);
  });
};

export default gtmPlugin;
