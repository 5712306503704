import Vue from 'vue';

import { createDebugger } from './fetch/utils';

type Payload = Record<string, any>

/**
 * Use Vue as a event bus
 */
const bus = new Vue();
const debug = createDebugger('hooks');

export const hook = (event: string, callback: (payload?: any) => any) => bus.$on(event, callback);
export const runHook = (event: string, payload?: Payload) => {
  debug(`Hook triggered: ${event}`);
  const onCompletedCallback = [];

  /**
   * Define completed callback
   * @param callback Callback function
   */
  const onCompleted = (callback: (payload?: Payload) => any) => {
    onCompletedCallback.push(callback);
  };

  /**
   * Report completed operation
   * @param payload Optional payload
   */
  const done = <T>(payload?: T) => {
    const doneEvent = `${event}:done`;
    debug(`Hook triggered: ${doneEvent}`);

    // Run completed callbacks
    onCompletedCallback.forEach(callback => callback(payload));

    // Emit done event
    bus.$emit(doneEvent, payload);
    bus.$emit('*', { event: doneEvent, payload });

    // Forward data and errors
    if (payload instanceof Error)
      return Promise.reject(payload);

    return Promise.resolve(payload);
  };

  bus.$emit(event, { onCompleted, ...payload });
  bus.$emit('*', { event, payload, onCompleted });

  return { done };
};
