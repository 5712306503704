import { defineAsyncComponent, defineComponent } from '@nuxtjs/composition-api';

import { RouterErrorType } from './error';
import { getPageComponent } from './page-fragment';

const MissingContentTypeComponent = defineAsyncComponent(() => import('../components/missing-content-type.vue'));
const NonceComponent = defineComponent({ render: h => h('div') });

/**
 * Resolve page compontent from type
 * @param pageContentType Page content type
 * @param errorCode Optional error code
 * @returns Lazy-loaded Vue component
 */
export const resolvePageComponent = (pageContentType: string, errorCode?: string) => {
  if (errorCode === RouterErrorType.ContentTypeNotImplemented)
    return MissingContentTypeComponent;

  if (!pageContentType)
    return NonceComponent;

  return defineAsyncComponent(() =>
    getPageComponent(pageContentType)
      .catch((error) => {
        console.error(error);
        return { render: h => h('div') };
      }),
  );
};
