import { defineComponent } from '@nuxtjs/composition-api';

/**
 * Use Vue composable as mixin
 * @param composable Composable function
 * @param args Set to false to only use types from composable, otherwise you can pass optional arguments to composable
 */
export const ComposableMixin = <T>(composable: (...args: any[]) => T, args: false | ((props: any) => any[]) = () => []) => {
  return defineComponent<T>({
    setup: (props) => {
      if (args === false)
        return;

      return composable(...args(props));
    },
  });
};
