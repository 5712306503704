import Vue from 'vue';
import LazyHydrate from 'vue-lazy-hydration';

import { getApolloClient } from '@/modules/apollo';
import {
  BlocksRenderer,
  PreviewButton,
  RecaptchaForm,
  AutolinkDirective,
  setApolloClient,
} from '@/modules/cms/internal';

Vue.component('Blocks', BlocksRenderer);
Vue.component('PreviewButton', PreviewButton);
Vue.component('RecaptchaForm', RecaptchaForm);
Vue.component('LazyHydrate', LazyHydrate);
Vue.directive('autolink', AutolinkDirective);

// Set apollo client
/** @type {import('@nuxt/types').Plugin} */
export default () => {
  setApolloClient(getApolloClient());
};
